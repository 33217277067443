// @flow
import React, { Component } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { connect }   from 'react-redux';
import { setNavButtons } from 'reducers/navbar.js';
import { login }     from 'actions/session';
import styled        from 'styled-components/native';
import snip          from 'styles/snip';
import colors        from 'styles/colors';
import Input         from 'components/Input';
import SceneCommon   from 'containers/SceneCommon';
import store         from '../../store.js';
import * as SESS     from 'actions/session';

const Link = styled(TouchableOpacity)`
	background-color: #fff;
	padding: 10px 0px;
`;

type Props = {
	history: Object,
	login:   Function,
	setNavButtons: Function,
};

type State = {
	email:      string,
	password:   string,
};

class Login extends Component {

	state: State;
	props: Props;

	constructor(props: Props) {

		super(props);

		this.state = {
			email:    '',
			password: '',
		};
	}

	componentWillUnmount() {
		this.props.setNavButtons(null);
	}

  	componentDidMount() {
		// this.props.setNavButtons(['back', 'Login']);
		SESS.authRefresh(store)
		.then((auth) => {
			this.props.history.push('/inspect');
		})
		.catch((err) => {
			console.error({ authRefresh: err });
		})
	}

	handleSubmit = (evt) => {

		this.props.login({
			email:		this.state.email,
			password: this.state.password,
		})
		.then(() => {
			this.props.history.push('/inspect');
		});
	};

	render() {

		const { email, password } = this.state;
		const margin = snip.pad;
		const msg = '* For new accounts, password defaults to your 10-digit mobile phone number.';
		const styleBtn = {
			fontFamily:      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
			fontWeight:      'bold',
			fontSize:        '1rem',
			borderStyle:     'none',
			borderRadius:    '3px',
			padding:         snip.pad,
			backgroundColor: colors.green_primary,
			color:           'white',
		};

		return (
			<SceneCommon>
				<View style={{ margin }}>
					<Text>{ msg }</Text>
				</View>
				<form onSubmit={(evt) => {
					evt.preventDefault();
					this.handleSubmit(evt);
				}}>
					<Input
						label="E-Mail"
						placeholder="you@example.com"
						onChangeText={(value) => this.setState({ email: value })}
						value={email}
					/>
					<Input
						type="password"
						label="Password*"
						placeholder="••••••"
						onChangeText={(value) => this.setState({ password: value })}
						value={password}
					/>
					<View style={{ padding: snip.pad }}>
						<input type="submit" value="Login" style={styleBtn} />
					</View>
				</form>
				<Link
					style={{ padding: margin, marginTop: '20vh' }}
					onPress={() => this.props.history.push('/forgotPassword')}
				>
					<Text style={{ color: colors.blue, fontWeight: '600' }}>
						Forgot Password
					</Text>
				</Link>
			</SceneCommon>
		);
	}
}

export default connect(null, { login, setNavButtons })(Login);
