// @flow
import React from 'react';
import { TouchableOpacity } from 'react-native';
import colors   from 'styles/colors';
import * as IO5 from 'react-icons/io5';
import * as TB from 'react-icons/tb';

type Props = {
  text?:     string,
  bold?:     boolean,
  iconName?: string,
  onPress:   Function,
  disabled?: boolean,
};

const iconSize = '1.2em';

const NavbarButton = (props: Props) => {

	const { text, iconName, onPress, bold, disabled } = props;
	const sClass = (props['class']) ? props['class'] : null;

	return (
		<TouchableOpacity onPress={() => (disabled ? false : onPress())} style={{
			cursor:        'pointer',
			fontSize:      '15pt',
			fontFamily:    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
			fontWeight:    bold ? 'bold' : 'normal',
			flexDirection: 'row',
			alignItems:    'center',
		}}>
				{(iconName === 'ios-arrow-back') &&	<IO5.IoArrowBack size={iconSize} color={colors.white} />}
				{(iconName === 'ios-close') &&	<IO5.IoClose size={iconSize} color={colors.white} />}
				{(iconName === 'ios-map') && <TB.TbMap2 size={iconSize} color={colors.white} />}
				{text &&
					<div
						className={sClass}
						style={{
							padding: '0px',
							marginLeft: '0.2em',
							marginRight: '0.2em',
							color: disabled ? 'rgb(200,205,210)' : colors.white,
						}}
					>{text}</div>
				}
				{(iconName === 'ios-camera') &&	<IO5.IoCamera size={iconSize} color={colors.white} />}
		</TouchableOpacity>
	);
};

export default NavbarButton;

