const colors = {
	red:      'rgb(255,59,48)',
	orange:   'rgb(255,149,0)',
	yellow:   'rgb(255,204,0)',
	green:    'rgb(76,217,100)',
	teal:     'rgb(90,200,250)',
	blue:     'rgb(0,122,255)',
	purple:   'rgb(88,86,214)',
	pink:     'rgb(255,45,85)',
	light_bg: 'rgb(242,242,242)',
	border:   '#CCC',
	white :	  '#ffff',
	green_primary :	  '#2ae3a2'
};

export default colors;

