import React from 'react';
import { View, Text } from 'react-native';
import colors from 'styles/colors';
import snip   from 'styles/snip.js';

const styleTxt = {
	...snip.bdrAll,
	borderColor: colors.green_primary,
	minWidth:          '5em',
	paddingVertical:   '0.3em',
	paddingHorizontal: '0.5em',
	margin:            '0.25rem',
	backgroundColor:   'white',
	fontSize:          '13pt',
	textAlign:         'center',
};

type Props = {
	tabs:       Array<string>,
	onClick:    Function,
	isSelected: Function,
	containerStyle: ?Object,
};

class TabNav extends React.Component {

	props: Props;

	render() {

		const { tabs, onClick, isSelected, containerStyle } = this.props;

		return (
			<View style={{
				display:         'flex',
				flexDirection:   'row',
				justifyContent:  'flex-start',
				backgroundColor: 'black',
				padding:         '0.25rem',
				...containerStyle
			}}>
			{
				tabs.map((title, ix) => {

					let styleSel = isSelected(ix) ? {color: 'white', backgroundColor: colors.green_primary, fontWeight: 'bold'} : {};

					return (
						<Text
							key={ix}
							onClick={onClick(ix)}
							style={{ ...styleTxt, ...styleSel }}
						>{title}</Text>
					);
				})
			}
			</View>
		);
	}
}

export default TabNav;
