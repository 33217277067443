// @flow
import React from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';
import filter from 'lodash/filter';
import snip                 from 'styles/snip.js';
import { Inspection, User } from 'types';
import { fetchInspections } from 'actions/inspection';
import InspectionList       from 'components/InspectionList';
import NoResults            from 'components/NoResults';
import TabNav               from 'components/TabNav';
import { SET_HISTORY_TAB_IX } from 'constants.js';

type Props = {
	currentUser:      User,
	fetchInspections: Function,
	arInsp:           Array<Inspection>,
};

class InspectionHistory extends React.Component {

	props: Props;

	componentDidMount() {
		this.handleRefresh();
	}

	handleRefresh = () => {
		return this.props.fetchInspections(this.props.currentUser.id);
	};

	render() {

		const { arInsp, historyTabIndex, setTabIx } = this.props;

		let inspections;
		switch( historyTabIndex ) {

		case 0:
			inspections = filter(arInsp, { status: 'review' });
			break;

		case 1:
			inspections = filter(arInsp, { status: 'completed' });
			break;

		default:
			inspections = arInsp;
			break;
		}

		const tabs = ['Review', 'Completed', 'All'];

		const fnClick = (ix) => {
			return (evt) => setTabIx(ix);
		};

		return (
			<View>
				<TabNav
					tabs={tabs}
					onClick={fnClick}
					isSelected={(ix) => (ix === historyTabIndex)}
					containerStyle={{ flexDirection: 'row-reverse', borderTop: snip.bdr }}
				/>
				{(inspections.length === 0) &&
					<NoResults label="No Results">
						Find completed inspections here after submission
					</NoResults>
				}
				{(inspections.length > 0) && <InspectionList {...this.props} inspections={inspections} />}
			</View>
		);
	}
}

export default connect(
	(state) => ({
		arInsp:          state.inspection.history,
		historyTabIndex: state.inspection.historyTabIndex,
		currentUser:     state.session.currentUser,
	}),
	{
		fetchInspections,
		setTabIx: (index) => { return (dispatch) => dispatch({ type: SET_HISTORY_TAB_IX, index }); },
	}
)(InspectionHistory);

