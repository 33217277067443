export const UPDATE_NAV = 'UPDATE_NAV';
export const ALERT      = 'ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const LOGIN      = 'LOGIN';
export const LOGOUT     = 'LOGOUT';
export const WAITING    = 'WAITING';
export const FETCH_INSPECTIONS_SUCCESS = 'FETCH_INSPECTIONS_SUCCESS';
export const SUBMIT_INSPECTION_SUCCESS = 'SUBMIT_INSPECTION_SUCCESS';
export const UPDATE_PROFILE_SUCCESS    = 'UPDATE_PROFILE_SUCCESS';
export const SET_HISTORY_TAB_IX        = 'SET_HISTORY_TAB_IX';
export const FETCH_LOAN_SUCCESS        = 'FETCH_LOAN_SUCCESS';
export const FETCH_INSP_WITH_ENTRIES_SUCCESS = 'FETCH_INSP_WITH_ENTRIES_SUCCESS';
export const CHAN_ENTRY_CREATED = 'CHAN_ENTRY_CREATED';
export const CHAN_ENTRY_UPDATED = 'CHAN_ENTRY_UPDATED';
export const INSPECTION_QUESTIONS_SAVED = 'INSPECTION_QUESTIONS_SAVED';