// @flow
import React  from 'react';
import LDSH   from 'lodash';
import { View, Text, ProgressBar, TouchableOpacity } from 'react-native';
import { connect }                           from 'react-redux';
import styled                                from 'styled-components/native';
import * as IO5                              from 'react-icons/io5';
import type { Inspection, User }             from 'types';
import { fetchInspection, submitInspection, checkImages } from 'actions/inspection';
import { fetchConstants }                    from 'actions/session.js';
import { showAlert }                         from 'actions/alert';
import { fetchLoan }                         from 'actions/loan';
import { createEntry, updateEntry }          from 'actions/loan';
import { setNavButtons, backBtn }            from 'reducers/navbar.js';
import * as UTL                              from 'utils';
import colors                                from 'styles/colors.js';
import snip                                  from 'styles/snip.js';
import ImageItem                             from './components/ImageItem';
import ImageItem2                            from './components/ImageItem2';
import SceneCommon from 'containers/SceneCommon';
import SelectItem  from 'components/SelectItem';
import Questionnaire from 'components/Questionnaire'
import Dialog      from 'components/Dialog';
import EvpCamera   from 'scenes/EvpCamera';
import EvpCamera2  from 'scenes/EvpCamera/EvpCamera2';
import OS          from 'utils/offlineStore.js';
import store       from 'store.js';
import { WAITING } from 'constants.js';
import { saveInspectionQuestions } from 'actions/questions'

export const InspectionInfo = styled(View)`
	padding:          0.5em 0.6em;
	border-width:     1px;
	border-color:     rgb(220,220,220);
	border-radius:    2px;
	background-color: #fff;
`;

export const ImageGrid = styled(View)`
	/* display: inline-block; */
	width:   100%;
	padding: 0px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
`;

export const ImageGridConstruction = styled(View)`
	width:   100%;
	min-height: calc(100vh - 350px);
	border-width:     1px;
	border-color:     rgb(220,220,220);
	border-radius:    2px;
	background-color: #fff;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2em;
	padding: 2em;
`;

const arPerc = [
	{ id: 10, name: 10 },
	{ id: 20, name: 20 },
	{ id: 25, name: 25 },
	{ id: 30, name: 30 },
	{ id: 40, name: 40 },
	{ id: 50, name: 50 },
	{ id: 60, name: 60 },
	{ id: 70, name: 70 },
	{ id: 75, name: 75 },
	{ id: 80, name: 80 },
	{ id: 90, name: 90 },
	{ id: 100, name: 100 },
]

type Props = {
	currentUser:      User,
	showAlert:        Function,
	fetchInspection:  Function,
	submitInspection: Function,
	setNavButtons:    Function,
	fetchLoan:        Function,
	createEntry:      Function,
	updateEntry:      Function,
	checkImages:      Function,
	lineItems:        Array<Object>,
	inspEntries:      Array<Object>,
};

type State = {
	inspection:          Inspection,
	nReqDone:            number,
	nReq:                number,
	ptypes:              Array<Object>,
	activePage:          string,
	photoOpts:           Object,
	inspClsToPhotoType:  Object,
	inspClsLabel:        Array,
	selectedPercentage:  Object,
	oldAllocation:       number,
	allocationItems:     Array<Object>,
	currentLineItem:     Object,
	selectedLineItem:    Object,
	finalSubmitDialog:   boolean,
	addMorePhotosDialog: boolean,
	imageSyncDialog:     boolean,
	maxPhotoCountSnap:   number,
	nPtypeDone:			 Number,
};

const Txt = (props) => {
	const propsNew = { ...props, style: { fontSize: 'inherit', ...props.style } };
	return <Text {...propsNew} />;
};

var interval;

class ShowInspection extends React.Component {

	props: Props;
	state: State;

	constructor(props) {

		super(props);

		this.state = {
			inspection:          null,
			nReqDone:            0,
			nReq:                0,
			ptypes:              [],
			inspClsLabel:        [],
			inspClsToPhotoType:  {},
			activePage:          'ShowInspection',
			photoOpts:           {},
			selectedPercentage:  {},
			oldAllocation:       0,
			allocationItems:     [],
			currentLineItem:     {},
			selectedLineItem:    {},
			finalSubmitDialog:   false,
			addMorePhotosDialog: false,
			imageSyncDialog:     false,
			maxPhotoCountSnap: 40,
		};
	}

	navBack = () => {
		this.props.history.push('/inspect');
	};

	componentWillUnmount() {
		this.props.setNavButtons(null);
		clearInterval(interval);
	}

	componentDidMount() {
		this.initialize();
		interval = setInterval(() => {
			const reload = localStorage.getItem('reloadInspection');
			if(reload === 'true' && this.state.activePage === 'ShowInspection') {
				localStorage.setItem('reloadInspection', 'false');
				this.initialize();
			}
		}, 1000);
	}

	initialize = () => {
		fetchConstants()
			.then(async (consts) => {

				const { inspClsToPhotoType = {}, inspClsLabel = [], maxPhotoCountSnap = 40 } = consts;
				const { currentUser, match, fetchInspection, setNavButtons, fetchLoan } = this.props;
				const insp_id = parseInt(match.params.id);

				setNavButtons([backBtn(this.navBack, 'Home')]);

				await new Promise(res => setTimeout(res, 1000));
				fetchInspection(currentUser.id, insp_id)
					.then((inspection) => {

						// CALC & CACHE REQ COUNTS
						const ptypes     = UTL.getByInspCls(inspClsToPhotoType, inspection.insp_cls);
						const ptypes_req = UTL.filterByReqd(ptypes);
						const nReq       = ptypes_req.length;
						const nReqDone   = UTL.getReqdFulfilledCt(ptypes_req, inspection.images);
						const bComplete  = (nReq === nReqDone);
						const nPtypeDone  = UTL.getReqdFulfilledCt(ptypes, inspection.images)

						// CONDITIONALLY SHOW 'SUBMIT' BUTTON
						if( bComplete && (inspection.status === 'pending') ) {
							let hasQuestionsSet = false;
							let isFoxyEnabled = inspection?.foxy_ai_report ?? false;
							if(inspection && inspection.question_details){
								hasQuestionsSet = inspection.question_details.questions !== null && inspection.question_details.questions.length > 0;
							}
							hasQuestionsSet && !isFoxyEnabled ? this.props.setNavButtons([
								backBtn(this.navBack,'Home'),
								{ label: 'Continue', action: this.handleCompleteNonConstruction, class: 'btnThrob' }
							])
							:
							this.props.setNavButtons([
								backBtn(this.navBack, 'Home'),
								{ label: 'Submit', action: this.handleComplete, class: 'btnThrob' }
							])

						}

						this.setState({ inspection, nReq, nReqDone, ptypes, inspClsToPhotoType, inspClsLabel, maxPhotoCountSnap, nPtypeDone });
					
						var loan = inspection.loan;
						if(loan.type === 'Construction') {
							fetchLoan(loan.organization_id, loan.id)
							.then((_loan) => {
								if(_loan === undefined || _loan === 'undefined'){
									return Promise.reject({error:"token expired"})
								}
								var lineItems = _loan.line_items;
								var _nReq     = 1;
								var _nReqDone = UTL.getReqdFulfilledCtConstruction(lineItems, inspection.images);
								var _bComplete = (_nReqDone >= _nReq);

								var _lineItems = lineItems.filter(li => inspection.images.find(i => i.key === li.id));
								var allocItems = [];
								_lineItems.forEach((item, index) => {
									allocItems.push(
										{ 
											index: index,
											name: item.name,
											inspection_id: inspection.id,
											loan_line_item_id: item.id,
											allocation: 0,
											allocation_new: 0,
										}
									);
								});
								this.setState({ allocationItems: allocItems, currentLineItem:  allocItems[0]});

								// CONDITIONALLY SHOW 'SUBMIT' BUTTON
								if( _bComplete && (inspection.status === 'pending') ) {
									this.props.setNavButtons([
										backBtn(this.navBack,'Home'),
										{ label: 'Submit', action: this.fnShowFinalSubmitDialog, class: 'btnThrob' }
									]);
									this.setState({ nReq: _nReq, nReqDone: _nReq, nPtypeDone: _nReq });
								}
							});
						}

						// sessionStorage.removeItem('SelectedLineItem');
						var lineItem = sessionStorage.getItem('SelectedLineItem');
						if(lineItem) {
							// this.setState({ activePage: 'EvpCamera2' });
							lineItem = JSON.parse(lineItem);
							this.setState({ selectedLineItem: lineItem, addMorePhotosDialog: true });
						}

						const inspImages  = LDSH.get(inspection, 'images', []);
						if(inspImages.length >= maxPhotoCountSnap && ((loan.type !== 'Construction' && ptypes.length === nPtypeDone) || loan.type === 'Construction')){
							UTL.scrollToBottom();
						}
					});
			});
	}

	handleCompleteNonConstruction = () =>{
		this.setState({ activePage: 'AnswerTheQuestions'});
		const backToInspection = () => this.props.setNavButtons([
			backBtn(this.navBack,'Home'),
			{ label: 'Continue', action: this.handleCompleteNonConstruction, class: 'btnThrob' }
		]);
		this.props.setNavButtons([
			backBtn(() => { 
				this.setState({ activePage: 'ShowInspection' });
				backToInspection();
			}, 'Back'),
		]);
	}

	handleComplete = async() => {

		const { currentUser, submitInspection, checkImages, showAlert, history } = this.props;
		const { inspection, nReq, nReqDone } = this.state;

		OS.All('pendingAPI')
		.then(async (requests) => {
			var count = requests.filter(r => r.api === "uploadImage")
			.filter(r2 => r2.data.inspection.id === inspection.id).length;

			if(count === 0 || !UTL.isOnline()) {
				store.dispatch({ type: WAITING, bWait: false });
				await new Promise(res => setTimeout(res, 1000));
				if( LDSH.isObjectLike(inspection) && (nReq === nReqDone) ) {
					if(UTL.isOnline()) {
						checkImages(currentUser.id, inspection.id)
						.then((rsp) => {
							if(rsp.image_uploaded === true) {
								return submitInspection(currentUser.id, inspection.id)
									.then(() => {
										history.push('/inspect');
									});
							}
						});
					} else {
						return submitInspection(currentUser.id, inspection.id)
							.then(() => {
								history.push('/inspect');
							});
					}
				}
				else {

					showAlert('Please add each photo type', 'Incomplete', 'verr');
				}
			} else {
				this.handleImageSyncDialog();
			}
		});
	};

	handleCompleteConstruction = async () => {
		const { allocationItems } = this.state;
		const { inspEntries, createEntry, updateEntry } = this.props;
		var hasError = false;

		for(let i = 0; i < allocationItems.length; i++) {
			const item = LDSH.cloneDeep(allocationItems[i]);
			var inspEntry = inspEntries.find((e) => e.inspection_id === item.inspection_id && e.loan_line_item_id === item.loan_line_item_id);
			if(inspEntry) {
				inspEntry.allocation = item.allocation_new;
				inspEntry = updateEntry(inspEntry);
			} else {
				inspEntry = {
					inspection_id: item.inspection_id,
					loan_line_item_id: item.loan_line_item_id,
					allocation: item.allocation_new,
				}
				inspEntry = createEntry(inspEntry);
			}
			await new Promise(res => setTimeout(res, 1000));
			const resp = await Promise.resolve(inspEntry);
			if(resp.hasOwnProperty('verr'))
				hasError = true;
		};
		
		if(!hasError) {
			this.handleComplete();
		}
	}

	fnSelectPercentagePage = (index = 0, disabled = true) => {
		this.setState({ oldAllocation: 0, selectedPercentage: {} });
		const { allocationItems } = this.state;
		const { inspEntries } = this.props;

		const item = allocationItems[index];
		const arEntries = LDSH.filter(inspEntries, { loan_line_item_id: item.loan_line_item_id });
		const inspEntry = inspEntries.find((e) => e.inspection_id === item.inspection_id && e.loan_line_item_id === item.loan_line_item_id);
		var allocation = arEntries.reduce((total, entry) => { return(total + Number(entry.allocation)) }, 0);
		var old_alloc = allocation;
		this.setState({ oldAllocation: allocation });
		if(item.allocation > 0) {
			allocation = item.allocation;
		}
		var percObj = arPerc.find(p => p.id === allocation);
		if(percObj) {
			var allocItems = this.state.allocationItems;
			allocItems[index].allocation = percObj.name;
			allocItems[index].allocation_new = percObj.name - old_alloc + Number(inspEntry ? inspEntry.allocation : 0);
			this.setState({ selectedPercentage: percObj, allocationItems: allocItems });
			disabled = false;
		}

		var fnBack = () => {
			this.fnSelectPercentagePage(index - 1);
			this.setState({ currentLineItem: allocationItems[index - 1] });
		};
		var fnNext = () => {
			this.fnSelectPercentagePage(index + 1);
			this.setState({ currentLineItem: allocationItems[index + 1] });
		};
		if(index === 0) {
			fnBack = () => {
				this.props.setNavButtons([
					backBtn(this.navBack, 'Home'),
					{ label: 'Submit', action: this.fnShowFinalSubmitDialog, class: 'btnThrob' }
				]);
				this.setState({ activePage: 'ShowInspection' });
				UTL.scrollToTop();
			};
		}
		if(index === (allocationItems.length - 1)) {
			fnNext = this.handleCompleteConstruction;
		}

		this.props.setNavButtons([
			backBtn(fnBack),
			item.name,
			{ label: 'Next', action: fnNext, disabled: disabled }
		]);
		this.setState({ activePage: 'SelectPercentage' });
		UTL.scrollToTop();
	}

	fnSelectPercentage = (item) => {
		const { currentLineItem } = this.state;
		var allocItems = this.state.allocationItems;
		allocItems[item.lineItemIndex].allocation = item.name;
		this.setState({ selectedPercentage: item, allocationItems: allocItems });
		this.fnSelectPercentagePage(currentLineItem.index, false);
	}

	fnHandleFinalSubmit = (confirm) => {
		this.setState({ finalSubmitDialog: false });
		if(confirm) {
			const { inspection } = this.state;
			if(inspection.loan.type === 'Construction')
				this.fnSelectPercentagePage();
			else
				this.handleComplete();
		}
	}

	fnShowFinalSubmitDialog = () => {
		const { currentUser, checkImages } = this.props;
		const { inspection } = this.state;

		OS.All('pendingAPI')
		.then(async (requests) => {
			var count = requests.filter(r => r.api === "uploadImage")
			.filter(r2 => r2.data.inspection.id === inspection.id).length;

			if(count === 0 || !UTL.isOnline()) {
				store.dispatch({ type: WAITING, bWait: false });
				if(UTL.isOnline()) {
					checkImages(currentUser.id, inspection.id)
					.then((rsp) => {
						if(rsp.image_uploaded === true) {
							this.setState({ finalSubmitDialog: true });
						}
					});
				} else {
					this.setState({ finalSubmitDialog: true });	
				}
			} else {
				this.handleImageSyncDialog();
			}
		});
	}

	handleImageSyncDialog = (callback=null) => {
		store.dispatch({ type: WAITING, bWait: true });
		this.setState({ imageSyncDialog: false });
		if(callback) {
			callback();
		} else {
			setTimeout(() => {
				this.setState({ imageSyncDialog: true });
				store.dispatch({ type: WAITING, bWait: false });
			}, 30000);
		}
	}

	navigateToImage = (image) => {

		const { inspection_id, id } = image;
		this.props.history.push(`/showImage/${inspection_id}/${id}`);
	};

	navigateToCamera = (photoOpts) => {

		const { inspection, maxPhotoCountSnap, ptypes, nPtypeDone } = this.state;
		// const { type, key } = photoOpts;
		// const insp_id = parseInt(inspection.id);

		const images  = LDSH.get(inspection, 'images', []);
		if(images.length >= maxPhotoCountSnap && ((inspection.loan.type !== 'Construction' && ptypes.length === nPtypeDone) || inspection.loan.type === 'Construction')){
			UTL.scrollToBottom();
			return
		}

		if(inspection.loan.type === 'Construction') {
			// this.props.history.push(`/snap/${insp_id}`);
			this.setState({ activePage: 'EvpCamera2' });
			UTL.scrollToTop();
		}
		else {
			// this.props.history.push(`/snap/${insp_id}/${type}/${key}`);
			this.setState({ photoOpts, activePage: 'EvpCamera' });
			UTL.scrollToTop();
		}
	};

	handleAddMorePhotos = (addMore) => {
		const { inspection, maxPhotoCountSnap, ptypes, nPtypeDone} = this.state;
		const images  = LDSH.get(inspection, 'images', []);
		if(images.length >= maxPhotoCountSnap){
			sessionStorage.removeItem('SelectedLineItem');
			this.setState({ addMorePhotosDialog: false });
			if((inspection.loan.type !== 'Construction' && ptypes.length === nPtypeDone) || inspection.loan.type === 'Construction'){
				UTL.scrollToBottom();
			}
			return
		}
		if(addMore) {
			this.setState({ activePage: 'EvpCamera2', addMorePhotosDialog: false });
			UTL.scrollToTop();
		} else {
			sessionStorage.removeItem('SelectedLineItem');
			this.setState({ addMorePhotosDialog: false });
		}
	}

	handleSaveQuestions = (data) =>{
		const { inspection } = this.state;
		const { saveInspectionQuestions } = this.props;
		if (inspection){
			saveInspectionQuestions(inspection.organization.id, inspection.id, data)
		}
		this.handleComplete();
	}

	render() {

		const { inspection, nReqDone, nReq, ptypes, inspClsLabel,
			activePage, oldAllocation, selectedPercentage, allocationItems, photoOpts,
			currentLineItem, selectedLineItem, addMorePhotosDialog, finalSubmitDialog,
			imageSyncDialog, maxPhotoCountSnap, nPtypeDone } = this.state;
		const { lineItems, inspEntries, history } = this.props;

		if( !LDSH.isObjectLike(inspection) )
			return null;

		const isStr = (x) => ((typeof x === 'string') && (x.trim().length > 0));
		const { loan = null, organization = null } = inspection;

		const images  = LDSH.get(inspection, 'images', []);
		const vmargin = snip.pad;

		var ptypes2 = ptypes.map((PO) => PO.type.replace(' ', '_'));
		var max_key = Math.max(...images.map((img) => img.key));
		var image_key = Number(max_key < 100 ? 100 : max_key + 1);

		var isConstructionLoan = inspection.loan.type === 'Construction'
		var maxPhotoUploaded = isConstructionLoan ? images.length >= maxPhotoCountSnap : images.length + (ptypes.length - nPtypeDone) >= maxPhotoCountSnap

		const formattedName = (name) =>{ return name.replace(/([^\w ]|_)/g, '').trim().split(" ").join("_");}

		let inspQuestions = [];
		let questionInstruction = "";
		if(inspection && inspection.question_details){
			if(inspection.question_details.questions !== null && inspection.question_details.questions.length > 0){
				inspQuestions = inspection.question_details.questions;
			}
			let instruction = inspection.question_details.question_instruction;
			if(isStr(instruction) && isStr(loan.address)){
				questionInstruction = instruction.replace(/\[.*?\]/g, loan.address);
			}
		}

		return (
			<React.Fragment>
				<Dialog
					visible={imageSyncDialog}
					message={`Large images can take a while to transfer. Try submitting your inspection again in a few minutes.`}
					onAccept={() => this.handleImageSyncDialog(inspection.loan.type === 'Construction' ? this.fnShowFinalSubmitDialog : this.handleCompleteNonConstruction)}
				/>
				<Dialog
					visible={finalSubmitDialog}
					message={`Please assign a percentage complete for any selected Line Items`}
					onAccept={() => this.fnHandleFinalSubmit(true)}
				/>
				<Dialog
					visible={addMorePhotosDialog}
					message={`Do you need to add more ${selectedLineItem.name} photos?`}
					onAccept={() => this.handleAddMorePhotos(true)}
					onReject={() => this.handleAddMorePhotos(false)}
					confirm={true}
				/>
				{activePage === 'ShowInspection' &&
					<SceneCommon style={{ padding: vmargin }}>
						<InspectionInfo style={{ marginBottom: vmargin }}>
							{ organization &&
								<Text style={{ fontSize: '1.4em', fontWeight: 'bold' }}>{organization.name}</Text>
							}
							<View style={{ flexDirection: 'row', marginBottom: '1em' }}>
								<Text style={{ color: 'rgb(130,145,160)' }}>
									{inspection.due_at && UTL.toYMD(inspection.due_at)}
									{inspection.loan.type !== 'Construction' && (
										<React.Fragment>
											{ " - " }
											{ UTL.transformInspCls(inspClsLabel, inspection) }
										</React.Fragment>
									)}
								</Text>
							</View>
							{ loan &&
								<View>
									{ isStr(loan.address)     && <Txt>{loan.address}</Txt> }
									<Txt>{loan.city}, {loan.state} {loan.zip}</Txt>
								</View>
							}
							{inspection.instructions &&
								<View style={{ fontSize: '0.9em', marginTop: '1em' }}>
									<Text style={{ fontWeight: 'bold' }}>Instructions</Text>
									<Text>{inspection.instructions}</Text>
								</View>
							}
						</InspectionInfo>
						{(inspection.insp_cls === 'IntOpt') &&
							<Text style={{
								...snip.bdrAll,
								fontStyle:       'italic',
								color:           '#444',
								backgroundColor: 'white',
								padding:         '0.5em',
								marginBottom:    vmargin,
							}}>
								NOTE: Federal lending standards require only exterior photographs of your property.
								However, by also taking interior photographs, you greatly increase the accuracy of the requested valuation report.
								These photographs are confidential and will only be used in your evaluation report.
							</Text>
						}
						{(inspection.status === 'pending') && inspection.loan.type !== 'Construction' &&
							<View style={{ marginBottom: vmargin, paddingHorizontal: '0.2em' }}>
								<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: '0.3rem' }}>
									{ (nReqDone === nReq) && <IO5.IoCheckbox color={colors.green} size="1.1em" style={{ marginRight: '0.25em' }} /> }
									<Text>{nReqDone} of {nReq} required photos complete</Text>
								</View>
								<ProgressBar
									indeterminate={false}
									progress={UTL.imagesCompleteRatio(nReq, nReqDone)}
								/>
							</View>
						}
						{(inspection.status === 'pending') && inspection.loan.type === 'Construction' && !maxPhotoUploaded &&
							<View style={{ marginBottom: vmargin, paddingHorizontal: '0.2em', flexDirection: 'row', alignItems: 'center' }}>
								<View style={{ flex: 1 }}>
									<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: '0.3rem' }}>
										<Text>Tap the camera to snap a photo</Text>
									</View>
								</View>
								<TouchableOpacity
									style={{ justifyContent: 'center' }}
									onPress={() => this.navigateToCamera({})}
								>
									<img
										src={`${process.env.PUBLIC_URL}/camera-icon.png`}
										alt="camera"
										style={{ width: '60%' }}
									/>
								</TouchableOpacity>
							</View>
						}
						{inspection.loan.type === 'Construction' ? 
							(
								<ImageGridConstruction>
								{
									lineItems.map((LI) => {
										const arImages = LDSH.filter(images, { type: formattedName(LI.name) });
										// const arEntries = LDSH.filter(inspEntries, { loan_line_item_id: LI.id });
										const arEntries = LDSH.filter(inspEntries, { loan_line_item_id: LI.id, inspection_id: inspection.id });
										var allocation = arEntries.reduce((total, entry) => { return(total + Number(entry.allocation)) }, 0);
										return(
											arImages.map((image, index) => {
												return (
													<ImageItem2 key={image.id} image={image}
														onNavigateToImage={this.navigateToImage}
														onNavigateToCamera={this.navigateToCamera}
														allocation={index === 0 ? allocation : NaN}
													/>
												);
											})
										);
									})
								}
								</ImageGridConstruction>
							) : 
							(
								<ImageGrid>
								{
									ptypes.map((PO) => {

										const image = LDSH.find(images, { type: PO.type.replace(' ', '_') });

										return (
											<ImageItem key={PO.key} image={image} photoOpts={PO}
												onNavigateToImage={this.navigateToImage}
												onNavigateToCamera={this.navigateToCamera}
												inspectionSubmitted={inspection.status !== 'pending'}
											/>
										);
									})
								}
								{
									images
									.filter((i) => !ptypes2.includes(i.type))
									.map((img) => {
										return (
											<ImageItem key={img.key} image={img}
												photoOpts={{
													color: "rgb(128,128,128)",
													instructions: "Take more photos of the property",
													key: img.key,
													required: false,
													type: img.type
												}}
												onNavigateToImage={this.navigateToImage}
												onNavigateToCamera={this.navigateToCamera}
												inspectionSubmitted={inspection.status !== 'pending'}
											/>
										);
									})
								}
								{
								!maxPhotoUploaded && (
								<ImageItem key={image_key} image={null} 
									photoOpts={{
										color: "rgb(128,128,128)",
										instructions: "Take more photos of the property.",
										key: image_key,
										required: false,
										type: "More"
									}}
									onNavigateToImage={this.navigateToImage}
									onNavigateToCamera={this.navigateToCamera}
									inspectionSubmitted={inspection.status !== 'pending'}
								/>)
								}
								</ImageGrid>
							)
						}
						{
							maxPhotoUploaded &&(
							<View style={{ margin:'0.5em' }}>
								<View style={{backgroundColor:'rgb(42,227,162)', borderRadius:'5px', padding:'10px'}}>
									<Text style={{ color: 'rgb(255,255,255)', fontWeight:'bold', fontSize:'15px' }}>
										{`You have reached the max number of photos.`}
									</Text>
								</View>
							</View>
							)
						}
					</SceneCommon>
				}
				{activePage === 'SelectPercentage' &&
					<SceneCommon style={{ padding: vmargin }}>
						{allocationItems.map((al, index1) => {
							return(
								<React.Fragment
									key={'LineItem-' + index1}
								>
									{currentLineItem.index === index1 && 
									arPerc
									.filter(p => p.name >= oldAllocation)
									.map((item, index) => {
										return(
											<SelectItem
												key={'Percentage-' + index}
												item={{...item, lineItemIndex: index1}}
												selectedItem={selectedPercentage}
												onPress={this.fnSelectPercentage}
											/>
										);
									})}
								</React.Fragment>
							);
						})}
					</SceneCommon>
				}
				{activePage === 'AnswerTheQuestions' &&
					<SceneCommon>
						<Questionnaire
						 questions={inspQuestions}
						 questionInstruction={questionInstruction}
						 onSave={this.handleSaveQuestions}
						/>
					</SceneCommon>
				}
				{activePage === 'EvpCamera' &&
					<EvpCamera
						history={history}
						inspection={inspection}
						photoOpts={photoOpts}
						onChangePage={(page) => { 
							this.setState({activePage: page});
							if(page === 'ShowInspection')
								this.initialize();
						}}
					/>
				}
				{activePage === 'EvpCamera2' &&
					<EvpCamera2
						history={history}
						inspection={inspection}
						onChangePage={(page) => { 
							this.setState({activePage: page});
							if(page === 'ShowInspection')
								this.initialize();
						}}
					/>
				}
			</React.Fragment>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.session.currentUser,
		lineItems:   state.loan.lineItems,
		inspEntries: state.loan.inspEntries,
	}),
	{
		showAlert,
		fetchInspection,
		submitInspection,
		setNavButtons,
		fetchLoan,
		createEntry,
		updateEntry,
		saveInspectionQuestions,
		checkImages,
	}
)(ShowInspection);
