import { combineReducers } from 'redux';
import alert      from './alert.js';
import session    from './session.js';
import inspection from './inspection.js';
import navbar     from './navbar.js';
import loan       from './loan.js';
import questions from './questions.js';

const reducers = combineReducers({
  alert,
  session,
  inspection,
	navbar,
  loan,
  questions
});

// eslint-disable-next-line
export default function (state, action) {
  return reducers(state, action);
}
