import Dexie from 'dexie';

const DB_NAME = 'waivit_offline';
const DB_VER  = 2;

const getStore = (prefix) => {

	let db = new Dexie(DB_NAME);

	db.version(DB_VER).stores({
		consts:      'current',
        inspections: 'id,loan_id',
		geolocation: 'current',
		login:       'data.email',
		pendingAPI:  'id,api',
	});

	return db[prefix];
};

const cleanObject = (obj) => {

	if (obj instanceof Blob) {
        return obj;
    }
	
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(cleanObject);
    }

    const newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (typeof value !== 'function') {
                newObj[key] = cleanObject(value);
            }
        }
    }
    return newObj;
};

export default class LocalStore {

	static Destroy() {
		return Dexie.delete(DB_NAME);
	}

	static Store(prefix) {
		return getStore(prefix);
	}

	static Put(prefix, vData) {
		const cleanedData = cleanObject(vData);
		return getStore(prefix).put(cleanedData);
	}

	static BulkPut(prefix, data) {
		const cleanedData = data.map(cleanObject);
        return getStore(prefix).bulkPut(cleanedData);
	}

	static Count(prefix) {
		return getStore(prefix).count();
	}

	static Get(prefix, key) {
		return getStore(prefix).get(key)
	}

	static All(prefix) {
		return getStore(prefix).toArray();
	}

	static Delete(prefix, key) {
		return getStore(prefix).delete(key);
	}

	static GetImage(inspectionId, imageId) {

		return getStore('inspections').get(inspectionId)
			.then((insp) => {

				if( insp && Array.isArray(insp.images) ) {
					return Promise.resolve(insp.images.find((I) => ( I.id === imageId )));
				}

				return Promise.resolve(undefined);
			});
	}
}

