import axios from 'axios';
import LDSH  from 'lodash';
import LS    from 'utils/localStore.js';
import OS    from 'utils/offlineStore.js';
import store from '../store.js';
import { HIDE_ALERT } from 'constants.js';
import {
    fetchInspections,
    uploadPendingImage
} from 'actions/inspection';
import * as SESS from 'actions/session';

function handleImageUploadSuccess(request, image) {
    OS.Delete('pendingAPI', request.id);
    OS.Store('pendingAPI')
    .filter((V) => V.api === 'addComment')
    .toArray((ret) => {
        var url = window.location.href;
        if(url.includes(request.id)) {
            // url = url.replace(request.id, image.id);
            // window.location.replace(url);
            sessionStorage.setItem('OldNewImageId', JSON.stringify({old: request.id, new: image.id}));
        }
        if( Array.isArray(ret) && (ret.length > 0) ) {
            ret.forEach((x) => {
                if(`${x.url}`.includes(request.id)) {
                    x.url = x.url.replace(request.id, image.id);
                    OS.Put('pendingAPI', x);
                }
            });
        }
    })
}

function _stopUpload(fetchInsp) {
    OS.All('pendingAPI')
    .then(async (requests) => {
        if(requests.length > 0) {
            setTimeout(function() { uploadOfflineData(); }, 5000);
        }
        else {
            localStorage.setItem('isUploading', 'false');
            LS.Get('auth', 1)
            .then((auth) => {
                if(fetchInsp) {
                    fetchInspections(auth.data.id)(store.dispatch);
                } else {
                    localStorage.setItem('reloadInspection', 'true');
                }
            })
            .catch(err => Promise.reject(err));
        }
    });
}

function stopUpload(fetchInsp = true) {
    _stopUpload(fetchInsp);
}

var fetchInsp = true;

function uploadData(request, data, user_id) {
    switch(request.api) {
        case 'uploadImage':
            return axios.get(`/users/${user_id}/inspections/${data.inspection.id}`)
            .then((insp) => {
                var image = insp.data.images.find(i => i.image_id === request.id);
                if(!image) {
                    return uploadPendingImage(data.user, data.inspection, data.blob, data.type, data.key, data.latitude, data.longitude, data.tempId, request.id)
                    .then((rsp) => {
                        handleImageUploadSuccess(request, rsp.data);
                        fetchInsp = false;
                        return rsp;
                    })
                    .catch((ex) => {
                        if(ex?.error?.includes('not found')) {
                            OS.Delete('pendingAPI', request.id);
                            store.dispatch({ type: HIDE_ALERT });
                        }
                        return Promise.resolve(false);
                    });
                } else {
                    handleImageUploadSuccess(request, image);
                    fetchInsp = false;
                }
            })
            .catch((ex) => {
                if(ex?.error?.includes('not found')) {
                    OS.Delete('pendingAPI', request.id);
                    store.dispatch({ type: HIDE_ALERT });
                    OS.Store('pendingAPI')
                    .filter((V) => V.api === 'addComment')
                    .toArray((ret) => {
                        if( Array.isArray(ret) && (ret.length > 0) ) {
                            ret.forEach((x) => {
                                if(`${x.url}`.includes(request.id)) {
                                    OS.Delete('pendingAPI', x.id);
                                }
                            })
                        }
                    })
                } else {
                    return Promise.resolve(false);
                }
            });
        case 'deleteImage':
            return axios.delete(request.url)
            .then(() => {OS.Delete('pendingAPI', request.id)})
            .catch(() => {
                return Promise.resolve(false);
            });
        case 'createEntry':
            return LS.Get('inspections', data.inspection_id)
            .then(async (insp) => {
                return axios.get(`/users/${user_id}/inspections/${insp.id}`)
                .then(async () => {
                    return axios.get(`/organizations/${insp.organization_id}/inspections?page_size=100&loan_id=${insp.loan_id}`)
                    .then(async (resp) => {
                        let arInsp = LDSH.get(resp, 'data', []);
                        let entries = [];
                        arInsp.forEach((insp) => {
                            if(insp.entries)
                            entries = entries.concat(insp.entries);
                        });
                        const arEntries = LDSH.filter(entries, { loan_line_item_id: data.loan_line_item_id });
		                var allocation = arEntries.reduce((total, entry) => { return(total + Number(entry.allocation)) }, 0);
                        var alloc_diff = allocation + data.allocation - 100;
                        if(alloc_diff > 0) {
                            data.allocation = data.allocation - alloc_diff;
                        }
                        return axios.post(`/disb_entry`, data)
                        .then((rsp) => {
                            OS.Delete('pendingAPI', request.id);
                            OS.Store('pendingAPI')
                            .filter((V) => V.api === 'updateEntry')
                            .toArray((ret) => {
                                if( Array.isArray(ret) && (ret.length > 0) ) {
                                    ret.forEach((x) => {
                                        if(`${x.data.id}`.includes("Temp")) {
                                            x.data.id = rsp.data.id;
                                            OS.Put('pendingAPI', x);
                                        }
                                    })
                                }
                            })
                        })
                        .catch((ex) => {
                            if(ex?.error?.includes('duplicate key value') || ex?.error?.includes('not found')) {
                                OS.Delete('pendingAPI', request.id);
                                store.dispatch({ type: HIDE_ALERT });
                            } else {
                                return Promise.resolve(false);
                            }
                        });
                    });
                })
                .catch((ex) => {
                    if(ex?.error?.includes('not found')) {
                        OS.Delete('pendingAPI', request.id);
                        store.dispatch({ type: HIDE_ALERT });
                    } else {
                        return Promise.resolve(false);
                    }
                });
            })
        case 'updateEntry':
            return LS.Get('inspections', data.inspection_id)
            .then(async (insp) => {
                return axios.get(`/users/${user_id}/inspections/${insp.id}`)
                .then(async () => {
                    return axios.get(`/organizations/${insp.organization_id}/inspections?page_size=100&loan_id=${insp.loan_id}`)
                    .then(async (resp) => {
                        let arInsp = LDSH.get(resp, 'data', []);
                        let entries = [];
                        arInsp.forEach((insp) => {
                            if(insp.entries)
                            entries = entries.concat(insp.entries);
                        });
                        const arEntries = LDSH.filter(entries, { loan_line_item_id: data.loan_line_item_id });
		                var allocation = arEntries.reduce((total, entry) => { return(total + Number(entry.allocation)) }, 0);
                        var alloc_diff = allocation + data.allocation - 100;
                        if(alloc_diff > 0) {
                            data.allocation = data.allocation - alloc_diff;
                        }
                        return axios.patch(`/disb_entry/${data.id}`, data)
                        .then(() => {OS.Delete('pendingAPI', request.id)})
                        .catch((ex) => {
                            if(ex?.error?.includes('not found')) {
                                OS.Delete('pendingAPI', request.id);
                                store.dispatch({ type: HIDE_ALERT });
                            }
                            return Promise.resolve(false);
                        });
                    });
                });
            });
        case 'submitInspection':
            axios.get(`/users/${user_id}/inspections/${data.inspection.id}/check_images`)
                .then(async (rsp) => {
                    if(rsp.image_uploaded === true) {
                        return axios.patch(request.url, {isSubmittedOffline:true})
                        .then(() => {OS.Delete('pendingAPI', request.id)})
                        .catch((ex) => {
                            if(ex?.error?.includes('not found')) {
                                OS.Delete('pendingAPI', request.id);
                                store.dispatch({ type: HIDE_ALERT });
                            }
                            return Promise.resolve(false);
                        });
                    }
                });
                break;
        case 'addComment':
            if(!request.url.includes(request.tempImgId)) {
                return axios.post(request.url, data)
                .then((rsp) => {
                    sessionStorage.setItem('OldNewCommentId', JSON.stringify({old: request.id, new: rsp.data.id}));
                    OS.Delete('pendingAPI', request.id)
                })
                .catch((ex) => {
                    if(ex?.error?.includes('not found')) {
                        OS.Delete('pendingAPI', request.id);
                        store.dispatch({ type: HIDE_ALERT });
                    }
                    return Promise.resolve(false);
                });
            }
            break;
        case 'deleteComment':
            if(!request.url.includes(request.tempImgId)) {
                return axios.delete(request.url)
                .then(() => {OS.Delete('pendingAPI', request.id)})
                .catch(() => {
                    return Promise.resolve(false);
                });
            }
            break;
        case 'updateComment':
                if(!request.url.includes(request.tempImgId)) {
                    return axios.patch(request.url, data)
                    .then(() => {
                        OS.Delete('pendingAPI', request.id)
                    })
                    .catch((ex) => {
                        if(ex?.error?.includes('not found')) {
                            OS.Delete('pendingAPI', request.id);
                            store.dispatch({ type: HIDE_ALERT });
                        }
                        return Promise.resolve(false);
                    });
                }
                break;
        case 'saveInspectionQuestions':
                return axios.post(request.url,request.data)
                .then(() => {OS.Delete('pendingAPI', request.id)})
                .catch((ex) => {
                    if(ex.error.includes('not found')) {
                        OS.Delete('pendingAPI', request.id);
                        store.dispatch({ type: HIDE_ALERT });
                    }
                    stopUpload();
                    return;
                });
        default:
            break;
    }
}

function uploadOfflineData() {
    SESS.authRefresh(store, false)
    .catch((err) => {
        return
    })
    OS.All('pendingAPI')
	.then(async (requests) => {
        LS.Get('auth', 1)
        .then(async (auth) => {
            for(let i=0; i<requests.length; i++) {
                try{
                    fetchInsp = true;
                    const request = requests[i];
                    const data = request.data;
                    var res = await uploadData(request, data, auth.data.id);
                    if(res === false) {
                        break;
                    }
                }catch{
                    if(auth === undefined || auth === 'undefined'){
                        localStorage.setItem('isUploading', 'false');
                    }
                    return
                }
            }
            stopUpload(fetchInsp);
        })
        .catch(err => Promise.reject(err));
    });
}

export function uploadOfflineData2() {
    uploadOfflineData();
}
