// @flow
import React, { useState } from 'react';
import { View, Text, TouchableOpacity }  from 'react-native';
import * as IO5        from 'react-icons/io5';
import { DBG_BUILD }   from 'config/index';
import { useHistory }  from 'react-router-dom';
import styled          from 'styled-components/native';
import styled2         from 'styled-components';

const Shadow = styled(View)`
	z-index:  200;
	position: fixed;
	top:      ${DBG_BUILD ? '4.4rem' : '3rem'};
	bottom:   0px;
    width:    100%;
	margin:           0px;
	background-color: rgba(0, 0, 0, 0);

    display:  flex;
	flex-direction:  column;
	align-items: flex-end;
`;

const Sidepanel = styled2.div`
    padding-bottom: 25px;
    min-width: 100px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    overflow-x: hidden;
    transition: 0.5s;
    color: #fff;
    text-align: right;

    .link {
        padding: 8px 16px 8px 32px;
    }
    .link:hover {
        background-color: rgba(0, 0, 0, 0.55);
    }
`;

type Props = {
	logo: Any,
    loginPage: Boolean,
};

const Txt = (props) => {
	const propsNew = { ...props, style: { fontSize: 'inherit', color: '#fff', ...props.style } };
	return <Text {...propsNew} />;
};

const HamburgerMenu = ({ logo, loginPage }: Props) => {
    const history = useHistory();
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

	return (
		<React.Fragment>
            <View style={{ backgroundColor:'black', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '3rem',padding: '10px' }}>
                <img src={logo} alt="logo" style={{ height: '100%', width: 'auto' }}/>
                <Txt onPress={() => setIsSidebarVisible(!isSidebarVisible)} style={{ color: 'white', fontSize: '25px', paddingTop: '5px' }}>
                    {isSidebarVisible ? <IO5.IoClose size="1.5em"/> : <IO5.IoMenu size="1.5em"/>}
                </Txt>

            </View>
            {isSidebarVisible && !loginPage &&
                <Shadow onPress={() => setIsSidebarVisible(false)}>
                    <Sidepanel>
                        <TouchableOpacity onPress={() => { history.push('/inspect'); setIsSidebarVisible(false); }}>
                            <div className='link'>
                                <Txt>Inspections</Txt>
                            </div>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { history.push('/history'); setIsSidebarVisible(false); }}>
                            <div className='link'>
                                <Txt>History</Txt>
                            </div>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { history.push('/profile'); setIsSidebarVisible(false); }}>
                            <div className='link'>
                                <Txt>Profile</Txt>
                            </div>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { setIsSidebarVisible(false); }}>
                            <a
                                href="https://yourevp.zendesk.com/hc/en-us/categories/4410095575955-Inspect-Documentation"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                                style={{ textDecoration: 'none' }}
                            >
                                <Txt>Help</Txt>
                            </a>
                        </TouchableOpacity>
                    </Sidepanel>
                </Shadow>
            }
            {isSidebarVisible && loginPage &&
                <Shadow onPress={() => setIsSidebarVisible(false)}>
                    <Sidepanel>
                        <TouchableOpacity onPress={() => { history.push('/'); setIsSidebarVisible(false); }}>
                            <div className='link'>
                                <Txt>Back</Txt>
                            </div>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { setIsSidebarVisible(false); }}>
                            <a
                                href="https://yourevp.zendesk.com/hc/en-us/categories/4410095575955-Inspect-Documentation"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                                style={{ textDecoration: 'none' }}
                            >
                                <Txt>Help</Txt>
                            </a>
                        </TouchableOpacity>
                    </Sidepanel>
                </Shadow>
            }
        </React.Fragment>
	);
}

export default HamburgerMenu;
