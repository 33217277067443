// @flow
import React from 'react';
import { Text, View, Button } from 'react-native';
import { connect } from 'react-redux';
import styled from 'styled-components/native';
import { User }      from 'types';
import colors        from 'styles/colors.js';
import snip          from 'styles/snip.js';
import { logout }    from 'actions/session';
import { updateProfile } from 'actions/session';
import { showAlert } from 'actions/alert';
import Avatar        from 'components/Avatar';
import LinkButton    from 'components/LinkButton';
import Input         from 'components/Input';

const UserItem = styled(View)`
	flex-direction:   row;
	padding:          ${snip.pad};
	margin-bottom:    0px;
	background-color: #fff;
`;

type Props = {
  user:          User,
  logout:        Function,
	updateProfile: Function,
	showAlert:     Function,
};

type State = {
	name:     string,
  email:    string,
	phone:    string,
	password: string,
	password_confirmation: string,
	verrs: Object,
};

class Profile extends React.Component {

  props: Props;
  state: State;

  constructor(props: Props) {

		super(props);

		this.state = {
			name:     props.user.name,
			email:    props.user.email,
			phone:    props.user.phone,
			password: '',
			password_confirmation: '',
			verrs: {},
    };
  }

	onUpdateProfile = (evt) => {

		let userNew = {...this.state};

		// FILTER PWD FIELDS IF EMPTY
		const pwf = ['password', 'password_confirmation'];
		pwf.forEach((k) => {
			if( (typeof userNew[k] !== 'string') || (userNew[k].length === 0 ) )
				delete userNew[k];
		});

		return this.props.updateProfile(this.props.user.id, { user: userNew })
			.then((ret) => {

				// CLEAR PASSWORD FIELDS
				this.setState({ password: '', password_confirmation: '', verrs: {} });

				// PROMPT SUCCESS
				this.props.showAlert('Profile Updated', '', 'ok');

			})
			.catch((err) => {

				// REPORT VERRS
				if( err.hasOwnProperty('verr') )
					this.setState({ verrs: err.verr });

				return Promise.reject(err);
			});
	};

  render() {

    const {user, logout} = this.props;

		let fnPush = (route) => this.props.history.push(route);

		const arFields = [
			{label: 'Name',          key: 'name'},
			{label: 'E-Mail',        key: 'email'},
			{label: 'Telephone',     key: 'phone'},
			{label: 'Password',      key: 'password'},
			{label: 'Password Conf', key: 'password_confirmation'},
		];

    return (
			<View>
				<UserItem>
					<Avatar email={user.email} />
					<View style={{ marginLeft: '0.7em', paddingTop: '0.1em' }}>
						<Text style={{ marginBottom: '0.25em' }}>{user.name}</Text>
						<Text style={{ fontSize: '0.9em' }}>{user.email}</Text>
					</View>
				</UserItem>
				<View>
					{
						arFields.map((V, ix) => (
							<Input
								key={ix}
								label={V.label}
								verr={this.state.verrs[V.key]}
								value={this.state[V.key]}
								onChangeText={(value) => this.setState({ [V.key]: value })}
							/>
						))
					}
					<View style={{ padding: '0.5em', paddingLeft: '70%' }}>
						<Button
							title="Update"
							onPress={this.onUpdateProfile}
							color={colors.green_primary}
						/>
					</View>
				</View>
				<LinkButton
					style={{ marginTop: '4em', marginBottom: '4em' }}
					onPress={() => {
						return logout().finally(() => { fnPush('/'); });
					}}
				>
					<Text style={{ fontWeight: 'bold', color: colors.red }}>Logout</Text>
				</LinkButton>
      </View>
    );
  }
}

export default connect(
  (state) => {
    return { user: state.session.currentUser };
  },
  {
    logout,
		updateProfile,
		showAlert,
  }
)(Profile);

