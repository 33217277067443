// @flow
import * as React from 'react';
// import TabNav            from 'components/TabNav';
import Inspections       from 'scenes/Inspections';
import Profile           from 'scenes/Profile';
import InspectionHistory from 'scenes/InspectionHistory';
import SceneCommon       from 'containers/SceneCommon';

// const tabs  = ['INSPECT', 'HISTORY', 'PROFILE'];
// const route = ['/inspect', '/history', '/profile'];

// const ixToRoute = (ix) => {

// 	if( (ix >= 0) && (ix < route.length) )
// 		return route[ix];

// 	return false;
// };

class EvpTabBar extends React.Component {

  render() {

		const path = this.props.match.path;

		// const fnSelTab = (ix) => {
		// 	return (evt) => {
		// 		const nextPath = ixToRoute(ix);
		// 		if( nextPath !== false )
		// 			this.props.history.push(nextPath);
		// 	}
		// };

		// const isSelected = (ix) => {
		// 	const psel = ixToRoute(ix);
		// 	return (psel === path);
		// };

		let currentView;
		switch(path) {
			default:
			case '/inspect':
				currentView = <Inspections {...this.props}/>;
				break;
			case '/history':
				currentView = <InspectionHistory {...this.props}/>;
				break;
			case '/profile':
				currentView = <Profile {...this.props}/>;
				break;
		}

    return (
			<SceneCommon>
				{/* <TabNav
					tabs={tabs}
					onClick={fnSelTab}
					isSelected={isSelected}
				/> */}
				{ currentView }
			</SceneCommon>
    );
  }
}

export default EvpTabBar;

